import styled from '@emotion/styled'

export const ModalTitle = styled.h2`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.typography.font.npBold};
  font-size: ${(props) => props.theme.typography.size.desktop.title2}px;
  font-weight: bold;
  margin: 0;
  text-align: left;
  line-height: normal;
`

export const ModalSmallTitle = styled(ModalTitle)`
  font-size: 18px;
`

export const ModalSubtitle = styled.p<{ residentialOnlyError?: boolean }>`
  color: ${(props) => (props.residentialOnlyError ? props.theme.colors.danger : props.theme.colors.lightGrey)};
  font-family: ${(props) => props.theme.typography.font.np};
  font-size: ${(props) => props.theme.typography.size.desktop.body3}px;
  margin-top: 0;
  text-align: left;
`

export const ModalText = styled.p`
  font-family: ${(props) => props.theme.typography.font.si};
  font-size: ${(props) => props.theme.typography.size.desktop.body3}px;
  text-align: left;
`

export const ModalTextMono = styled.p`
  font-family: ${(props) => props.theme.typography.font.sim};
  font-size: ${(props) => props.theme.typography.size.desktop.body3}px;
  text-align: left;
`

export const ModalLinkSans = styled.a`
  font-family: ${(props) => props.theme.typography.font.si};

  &:link {
    font-family: ${(props) => props.theme.typography.font.si};
  }
`

export const Modal = styled.div`
  border-radius: ${(props) => props.theme.border.borderRadius.round}px;
  position: relative;
  overflow: auto;
  outline: none;
  padding: ${(props) => props.theme.spacing.larger}px ${(props) => props.theme.spacing.large}px;
  background: ${(props) => props.theme.colors.background};
  max-width: 325px;
  z-index: 11;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.noTopPadding {
    padding-top: 0;
  }

  &.noPadding {
    padding: 0;
  }
`

export const Message = styled.div`
  text-align: center;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium}px;
  margin-top: ${(props) => props.theme.spacing.medium}px;
`

export const Comment = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  font-family: ${(props) => props.theme.typography.font.npBold};
  font-size: ${(props) => props.theme.typography.size.desktop.title3}px;
  font-weight: bold;
  text-align: center;
`

export const Paragraph = styled.p`
  font-family: ${(props) => props.theme.typography.font.sim};
  font-size: ${(props) => props.theme.typography.size.desktop.body3}px;
  margin: 0 0 ${(props) => props.theme.spacing.medium}px;
  text-align: center;
`

// yes this is ugly, but one of the modals used the paragraph class on a div
export const ParagraphDiv = styled.div`
  font-size: 1.2rem;
  font-weight: 200;
  margin: 0;
`

export const LinkContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.medium}px;
`

export const LinkButton = styled.a`
  display: block;
  text-align: center;
  margin-top: 10px;
`

export const ButtonRow = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;

  & button {
    margin-bottom: 1rem;
  }
`

export const FullWidthButton = styled.div`
  width: 100%;
`

export const Emoji = styled.div`
  font-size: 8rem;
`

export const CloseModalIcon = styled.img`
  width: 25px;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  z-index: 5;
`

export const Logout = styled.div`
  padding: 0.5rem;
`
